import { axios } from '@/plugins/axios.js'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchQuotations(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/quotations', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchQuotation(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/quotations/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createQuotation(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/quotations', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateQuotation(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/quotations/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidQuotation(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/quotations/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  }
}