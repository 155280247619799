<template>
  <div id="app">
    <Snackbar />
    <router-view></router-view>
  </div>
</template>

<script>
import Snackbar from '@/components/snackbar.vue'

export default {
  name: 'app',
  components: {
    Snackbar,
  },
  data: () => ({})
}
</script>

<style>
.page-body {
  min-height: calc(100vh - 140px) !important;
}
.hover-point {
  cursor: pointer;
}
@media (max-width: 991px) {
  .page-body {
    min-height: calc(100vh - 120px) !important;
  }
}
</style>
