import Vue from "vue";
import Vuex from "vuex";
// import 'es6-promise/auto';
import layout from './modules/layout'
import menu from './modules/menu'
Vue.use(Vuex);

import itemStoreModule from './pos/itemStoreModule'
import customerStoreModule from './pos/customerStoreModule'
import orderStoreModule from './pos/orderStoreModule'
import adminOrderStoreModule from './admin/orderStoreModule'
import adminCustomerStore from './admin/customerStoreModule'
import returnStoreModule from './pos/returnStoreModule'
import warrantyStoreModule from './pos/warrantyStoreModule'
import quotationStoreModule from './quotationStoreModule'
import requisitionStoreModule from './requisitionStoreModule'
import userStoreModule from './userStoreModule'
import purchaseOrderStoreModule from './purchaseOrderStoreModule'
import supplierStoreModule from './supplierStoreModule'
import goodsReceivedStoreModule from './goodsReceivedStoreModule'

export const store = new Vuex.Store({
    state: {
      snackbar: {
        content: '',
        status: '',
        errors: [],
      },
      checkout: {
        items: [],
      },
    },
    getters: {
      isSnackbarVisible(state) {
        return state.snackbar.isSnackbarVisible
      },
    },
    mutations: {
      SHOW_SNACKBAR(state, value) {
        state.snackbar.content = value.content
        state.snackbar.status = value.status
        state.snackbar.errors = value.errors
      },
      SET_CHECKOUT_ITEMS(state, value) {
        state.checkout.items = value
      },
      SET_ITEM_QUANTITY(state, value) {
        state.checkout.items[value.index].quantity = value.new_quantity
      },
      SET_ITEM_DESCRIPTION(state, value) {
        state.checkout.items[value.index].description = value.description
      },
      SET_WARRANTIES(state, value) {
        state.checkout.items[value.index].warranties = value.warranties
      },
      SET_ITEM_PRICE(state, value) {
        state.checkout.items[value.index].selling_price = value.new_price
      },
      ADD_CHECKOUT_ITEM(state, value) {
        value.index && value.index >= 0 ? state.checkout.items.splice(value.index, 0, value.new_item) : state.checkout.items.push(value.new_item)
      },
      REMOVE_CHECKOUT_ITEM(state, value) {
        state.checkout.items.splice(value, 1)
      }
    },
    actions: {
      showSnackbar({ commit }, value) {
        commit('SHOW_SNACKBAR', value)
      },
      setCheckoutItems({ commit }, value) {
        commit('SET_CHECKOUT_ITEMS', value)
      },
      setItemQuantity({ commit }, value) {
        commit('SET_ITEM_QUANTITY', value)
      },
      setItemDesc({ commit }, value) {
        commit('SET_ITEM_DESCRIPTION', value)
      },
      setWarranties({ commit }, value) {
        commit('SET_WARRANTIES', value)
      },
      setItemPrice({ commit }, value) {
        commit('SET_ITEM_PRICE', value)
      },
      addCheckoutItem({ commit }, value) {
        commit('ADD_CHECKOUT_ITEM', value)
      },
      removeCheckoutItem({ commit }, value) {
        commit('REMOVE_CHECKOUT_ITEM', value)
      },
    },
    modules: {
      layout,
      menu,
      itemStore: itemStoreModule,
      customerStore: customerStoreModule,
      adminCustomerStore: adminCustomerStore,
      orderStore: orderStoreModule,
      adminOrderStore: adminOrderStoreModule,
      returnStore: returnStoreModule,
      warrantyStore: warrantyStoreModule,
      quotationStore: quotationStoreModule,
      userStore: userStoreModule,
      requisitionStore: requisitionStoreModule,
      purchaseOrderStore: purchaseOrderStoreModule,
      supplierStore: supplierStoreModule,
      goodsReceivedStore: goodsReceivedStoreModule,
    }
});

