import { axios } from "@/plugins/axios.js";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomers(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/v1/admin/customers", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCustomer(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/admin/customers/${params.id}`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchTransactions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/admin/customers/${params.id}/transaction`, {params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCustomer(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/admin/customers/${params.id}`, params)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    voidCustomer(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/admin/customers/${params.id}`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
