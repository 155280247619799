import { axios, baseURL } from '@/plugins/axios.js'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchOrders(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/orders', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/orders/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/orders', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/orders/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    tallyTotal(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/orders/tally', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/orders/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrderReceipt(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/orders/${params.id}/receipt`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDeliveryOrders(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/orders/delivery_orders', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    printReport(ctx, params) {
      return new Promise((resolve, reject) => {
        const urlParams = new URLSearchParams()
        urlParams.append('order_date', params.order_date)

        fetch(`${baseURL}/api/v1/orders.pdf?${urlParams}`, {
          method: 'GET',
          headers: $cookies.get('userAuth'),
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
  }
}