import { axios } from '@/plugins/axios.js'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomers(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/customers', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createCustomer(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/customers', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCustomer(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/customers/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCarBrands(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/car_brands')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}