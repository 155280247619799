const state = {
  searchData: [],
  togglesidebar: true
}

// getters
const getters = {

}

// mutations
const mutations = {
  opensidebar: (state) => {
    state.togglesidebar = !state.togglesidebar
  },
  resizetoggle: (state) => {
    if (window.innerWidth < 1199) {
      state.togglesidebar = false
    } else {
      state.togglesidebar = true
    }
  },
};

// actions
const actions = {
  opensidebar: (context, term) => {
    context.commit('opensidebar', term)
  },
  resizetoggle: (context, term) => {
    context.commit('resizetoggle', term)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}