import { axios, baseURL } from "@/plugins/axios.js";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSerialNumber(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/v1/variables/serial_number_ranges")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchOrders(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/v1/admin/orders", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/admin/orders/${params.id}`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    voidOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/admin/orders/${params.id}`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    printReport(ctx, params) {
      return new Promise((resolve, reject) => {
        const urlParams = new URLSearchParams();
        urlParams.append('sort_by', 'id')
        urlParams.append('sort_dir', 'asc')
        urlParams.append("search_text", params.search_text || "");
        urlParams.append("start_date", params.start_date || "");
        urlParams.append("end_date", params.end_date || "");
        urlParams.append("max_serial_number", params.max_serial_number || "")
        urlParams.append("order_type", params.order_type || "")
        urlParams.append("export_type", params.export_type || "Order")
        fetch(`${baseURL}/api/v1/admin/orders.xlsx?${urlParams}`, {
          method: "GET",
          headers: $cookies.get("userAuth"),
        })
          .then((response) => {
            resolve(response.blob());
          })
          .catch((error) => reject(error));
      });
    },
  },
};
