import { axios, baseURL } from '@/plugins/axios.js'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProducts(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/products', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createProduct(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/products', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProduct(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/products/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchProducts(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/products/active', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductGroups(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/product_groups')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategories(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/categories')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSubcategories(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/subcategories')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductBrands(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/product_brands')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductUnits(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/units')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createProductGroup(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/product_groups', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createCategory(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/categories', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createSubcategory(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/subcategories', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createProductBrand(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/product_brands', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createProductUnit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/units', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductSummary(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`api/v1/products/${params.id}/summary`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportStockList(ctx, params) {
      return new Promise((resolve, reject) => {
        const urlParams = new URLSearchParams();
        urlParams.append("product_brand_id", params.export_stock.product_brand_id || "");
        urlParams.append("main_group", params.export_stock.main_group || "")
        urlParams.append("product_group_id", params.export_stock.product_group_id || "")
        urlParams.append("category_id", params.export_stock.category_id || "")
        urlParams.append("location", params.export_stock.location || "")
        fetch(`${baseURL}/api/v1/products/stock_list?${urlParams}`, {
          method: "GET",
          headers: $cookies.get("userAuth"),
        })
          .then((response) => {
            resolve(response.blob());
          })
          .catch((error) => reject(error));
      });
    },
  },
}