import { axios } from '@/plugins/axios.js'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPurchaseOrders(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/purchase_orders', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPurchaseOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/purchase_orders/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createPurchaseOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/purchase_orders', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePurchaseOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/purchase_orders/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidPurchaseOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/purchase_orders/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    approvePurchaseOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/purchase_orders/${params.id}/approve`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}